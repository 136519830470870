
import { defineComponent } from 'vue';
import { useRepositories } from "@/repository/repositories";
import { InsuranceContractor } from '@/models/insurance_contractor';
import DocumentIcon from "@/components/document-icon.vue";

type DataType = {
  me?: InsuranceContractor;
};


export default defineComponent({
  name: "user-home",

  components: {
    DocumentIcon
  },

  setup() {
    const repos = useRepositories();
    return {
      repos,
    }
  },

  data(): DataType {
    return {
      me: undefined,
    }
  },

  computed: {
    showBuilding(): boolean {
      return this.me?.building !== undefined && this.me.building.length !== 0;
    }
  },

  methods: {
    async _fetchSelf(): Promise<void> {
      this.me = await this.repos.insuranceContractor.getSelf();
    },
    onClickIssueFee(): void {
      window.open("https://c8446fed.form.kintoneapp.com/public/fb4d59c5e627e8832fb5420830377f37c04c616a087bc3a98954bacaca8c63a8");
    }
  },

  mounted(): void {
    this._fetchSelf();
  }
});
